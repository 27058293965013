import React, { useState } from 'react'
import { motion } from "framer-motion";
import logoFix from '../../assets/img/logoWhite.png'
import logoScroll from '../../assets/img/logo.png'
import { Link } from 'react-router-dom';


function NavLink(props){
  const customIcon = "fa-solid fa-"+props.icon;
  const [arrow, arrowChange] = useState(false);

  const classHome = "py-7 px-9 text-gray-100 text-base font-medium hover:bg-gray-300/50 transition-all rounded-sm duration-500 hover:text-blue-900";
  const classScroll = "py-7 px-9 text-blue-900 text-base font-medium hover:bg-gray-300/50 transition-all rounded-sm duration-500 hover:text-blue-900";

  return(
    <>
        <motion.div initial={{ opacity : 0 , y: -50}} whileInView={{ opacity : 1, y : 0 }} transition={{ duration : props.pos*0.5}} className="relative flex flex-col items-center">
          <Link to={props.href}>
            <button onMouseEnter={() => arrowChange(true)} onMouseLeave={() => arrowChange(false)} className={props.scrollPos ? classScroll : classHome }> 
                <i className={customIcon}></i>  {props.text} 
            </button>
          </Link>
            {arrow && (
                <motion.div initial={{opacity: 0, y:-20}} whileInView={{ opacity : 1, y : 0 }} transition={{duration : 0.4}} >
                  <i  className=" pointer-events-none fa-solid fa-chevron-down text-blue-900 absolute animate-bounce bottom-0 mb-2"></i>
                </motion.div>        
            )}
        </motion.div>
    </>
  );
}


export default function HeaderScroll(props) {
  const headerHome = 'fixed h-32 w-full flex items-center justify-center transition-all duration-500';
  const headerScroll = 'fixed h-20 w-full bg-gray-200 shadow-md flex items-center justify-center transition-all duration-500 ';

  const imageHome = 'h-20 w-28 flex items-center mx-4 transition-all duration-300';
  const imageScroll = 'h-18 w-24 flex items-center mx-4 transition-all duration-300';
  return (    
    <>
      <div className={props.scrollControl ? headerScroll : headerHome}>
        <NavLink href="/" icon="house" text="HOME" pos="1" scrollPos={props.scrollControl}/>
        <NavLink href="/Products" icon="boxes-stacked" text="PRODUCTS" pos="2" scrollPos={props.scrollControl} />
        <a href="/">
          <div className={props.scrollControl ? imageScroll : imageHome}>
            { props.scrollControl ? (
              <img src={logoScroll} alt="" className='transition-all duration-300'/>
            ): 
            (
              <img src={logoFix} alt="" className='transition-all duration-300'/>
            )}
          </div>
        </a>
        <NavLink href="/About" icon="question" text="ABOUT US" pos="2" scrollPos={props.scrollControl} />
        <NavLink href="/Contact" icon="address-card" text="CONTACT US" pos="1" scrollPos={props.scrollControl} />
      </div>
    </>
  )
}
