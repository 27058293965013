import React from 'react'
import Footer from './Footer/Footer'
import Logo from '../assets/img/logo.png'
import Experience from '../assets/img/20yearsExperience.png'
import Departments from '../assets/img/departments.jpg'
import { Helmet } from 'react-helmet'

function About() {
  return (
    <>
        <Helmet>
            <title>A&F Technology | About us</title>
            <meta name="description" content="We are a specialized industrial engineering company known for leading advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration products, and water treatment solutions. With extensive expertise and a commitment to excellence, we offer top-quality solutions at competitive prices. Our focus on innovation, technical prowess, and customer satisfaction sets us apart as a trusted global leader." />
        </Helmet>
        {/*TopPage*/}
        <div className=' h-96 bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white'>
            <div className='text-center items-center justify-center flex flex-col h-full pt-10'>
                <h1 className=' lg:text-7xl md:text-5xl font-semibold text-3xl'>A&F Technology</h1>
                <h3 className=' md:text-2xl mt-6 font-thin'>The complete solution for your dream project.<br />We accompany you from conception to production.</h3>
            </div>
        </div>
        {/*PageContent*/}
        <div className=' container max-w-screen-xl mx-auto p-4 gap-5 my-4 grid grid-cols-1'>
            {/*BriedPresntation*/}
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-5 items-center'>
                <div className='lg:col-span-2 col-span-1'>
                    <h2 className=' text-blue-900 text-3xl font-semibold mb-3'>Who are we ?</h2>
                    <p className=' text-lg'>We are a specialized industrial engineering company known for leading advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration products, and water treatment solutions. With extensive expertise and a commitment to excellence, we offer top-quality solutions at competitive prices. Our focus on innovation, technical prowess, and customer satisfaction sets us apart as a trusted global leader.</p>
                </div>
                <div className=' w-full h-full flex justify-center items-center'>
                    <div className='col-span-1 p-8 w-72 h-72 rounded-full border-8 border-gray-50 shadow-xl'>
                        <div style={{ backgroundImage : `url(${Logo})` }} className='w-full h-full bg-no-repeat bg-center bg-contain'> </div>
                    </div>
                </div>
            </div>
            {/*Departments*/}
            <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-5 items-center'>
                <img src={Departments} alt="departements" className='lg:col-span-2 col-span-1 rounded-sm border-4 border-gray-50 shadow-xl' />
                <div className='lg:col-span-2 col-span-1'>
                    <h2 className=' text-blue-900 text-3xl font-semibold mb-3'>Departements</h2>
                    <p className=' text-lg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat quam praesentium aperiam, dolores itaque vero. Neque aspernatur eum unde ipsum obcaecati cumque illum illo accusamus error, minus repellendus, excepturi recusandae?
                    <br /> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum cupiditate natus, fugit aperiam delectus necessitatibus iusto. Dolores, perspiciatis hic cumque veniam facilis illum velit vitae eaque. Cumque quas quam obcaecati.
                    </p>
                </div>
            </div>
            {/*20YearsOFExperience*/}
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-5 items-center'>
                <div className='lg:col-span-2 col-span-1'>
                    <h2 className=' text-blue-900 text-3xl font-semibold mb-3'>Over 20 years of experience</h2>
                    <p className=' text-lg'>Established in 2002, our company swiftly garnered over 20 years of experience in the field of industrial engineering. Throughout this journey, we have become a trusted authority in advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration solutions, and water treatment solutions.</p>
                </div>
                <div className=' w-full h-full flex justify-center items-center'>
                    <div className='col-span-1 w-72 h-72 rounded-full shadow-xl'>
                        <div style={{ backgroundImage : `url(${Experience})` }} className='w-full h-full bg-no-repeat bg-center bg-contain'> </div>
                    </div>
                </div>
            </div>
        </div>
        {/* FOOTER */}
        <Footer/>
    </>
  )
}

export default About