import React from 'react'
import { useState } from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';

function ProductTypeCard({title, href, img, big}) {
    const [onHover, handleOnHover] = useState(false);

    const shadowImage = 'rounded-t-sm bg-black/50 h-full w-full transition-all duration-300';
    const shadowImageHover = ' rounded-t-sm bg-black/0 h-full w-full transition-all duration-300';

    const boxClass = ' h-20 bg-blue-900 rounded-b-sm text-white flex border-2 border-blue-900 items-center px-5 transition-all duration-500';
    const boxClassHover = ' h-20 bg-gray-200 rounded-b-sm text-blue-900 border-2 border-blue-900 flex items-center px-5 transition-all duration-500';

    const buttonClass = ' bg-gray-200 text-blue-900 px-4 py-2 font-medium rounded-sm transition-all duration-500';
    const buttonClassHover = ' bg-blue-900 text-white px-4 py-2 font-medium rounded-sm transition-all duration-500';

  return (
    <>
    <motion.div initial={{ opacity : 0 , y : 20 }} animate={{ opacity : 1 , y:0}} transition={{ duration : 1 }} viewport={{ once: true }} className={big ? 'lg:col-span-3 col-span-1' : 'lg:col-span-2 col-span-1' }>
        <Link to={href} onMouseEnter={()=>handleOnHover(true)} onMouseLeave={()=>handleOnHover(false)}>
            <div className=' w-full min-h-fit bg-white shadow-lg flex flex-col rounded-sm hover:scale-105 transition-all duration-300'>
                <div className=' flex-grow h-64 bg-center bg-no-repeat bg-cover rounded-t-sm' style={{ backgroundImage : `url(${img})` }}>
                    <div className={onHover ? shadowImageHover : shadowImage }></div>
                </div>
                <div className={onHover ? boxClassHover : boxClass}>
                    <h3 className=' font-semibold flex-grow text-xl'>{title}</h3>
                    <button className={onHover ? buttonClassHover : buttonClass}>See Products</button>
                </div>
            </div>
        </Link>
    </motion.div>
    </>
  )
}

export default ProductTypeCard