import React, { useEffect, useState } from 'react'
import HeaderScroll from './Header/HeaderScroll'
import { motion } from "framer-motion";
import HeaderMobile from './Header/HeaderMobile';


export default function HeaderFinal() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    
      const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
      };
    
      useEffect(() => {
        window.addEventListener('resize', detectSize)
    
        return () => {
          window.removeEventListener('resize', detectSize)
        }
      }, [windowDimenion]);
      const menuClass = windowDimenion.winWidth > 850 ? "fixed h-32 w-full z-50" : "fixed h-24 w-full z-50";
  return (
    <>
        <div className={menuClass}>
            <motion.div initial={{opacity : 0, y: -100}} animate={{ opacity: 1, y: 0}} exit={{opacity : 0}} transition={{ duration: 1}} >
                { windowDimenion.winWidth > 850 ? <HeaderScroll scrollControl={scrollPosition} /> :  <HeaderMobile scrollControl={scrollPosition}/>}
            </motion.div>
        </div>
     </>
    
  )
}
