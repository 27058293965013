import React from 'react';
import { useState , useContext } from 'react';
import logoScroll from '../../../assets/img/logoWhite.png';
import { FirebaseContext } from '../../../Firebase';
import { useNavigate } from "react-router-dom";

function MenuLink(props){
    return(
        <>
            <li>
                <a href={props.href} className="flex items-center p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700">
                <i className={props.icon}></i>
                <span className="ml-3">{props.text}</span>
                </a>
            </li>
        </>
    );
}

function AdminHeaderDesktop() {
    const firebase = useContext(FirebaseContext);
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(false);
    const showMenu = 'w-64 fixed left-0 top-0 h-screen transition-transform translate-x-0 z-40 duration-300';
    const hideMenu = 'w-64 fixed left-0 top-0 h-screen transition-transform -translate-x-full sm:translate-x-0 z-40 duration-300';


    const handleLogOut = ()=>{
        firebase.signOut()
        .then(()=>{
            navigate("/LoginAdmin");
        }).catch((error) =>{
            console.log(error);
        })
    }
        
    return (
        <>
            <button onClick={()=> setMenuState(true)} className="inline-flex items-center p-2 ml-3 mt-2 text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside className={menuState ? showMenu : hideMenu}>
                <div className="px-3 py-4 overflow-y-auto bg-gray-800 h-full">
                    <button onClick={()=> setMenuState(false)} className=' absolute text-gray-800 bg-white py-2 px-4 left-0 text-xl rounded-r-lg sm:hidden'><i className="fa-solid fa-xmark"></i></button>
                    <img src={logoScroll} className=" w-24 mx-auto mb-10" alt='logo' />
                    <ul className="space-y-2">
                        <MenuLink href="/AdminHome" text="Manage Products" icon="fa-solid fa-pen-to-square" />
                        <MenuLink href="/AdminContact" text="Contact Requests" icon="fa-solid fa-message" />
                    </ul>
                    <button onClick={handleLogOut} className='flex items-center p-2 text-base font-normal rounded-lg text-white bg-red-600 mt-2 w-full justify-center'><i className="fa-solid fa-power-off"></i> <span className="ml-3">Log Out</span></button>
                </div>
            </aside>
        </>
    )
}

export default AdminHeaderDesktop