import React, { useState } from 'react'
import {motion} from 'framer-motion'

function Card(props) {
  const [showContent, changeShowContent] = useState(false);

  const divClass = 'absolute bottom-0 text-center w-full bg-gray-600/70 text-gray-100 py-3 md:h-2/3 h-1/2 transition-all duration-700 px-2';
  const divClassShow = ' absolute bottom-0 text-center w-full bg-gray-500/60 text-gray-100 h-1/4 py-3 transition-all duration-700 px-2';

  const contentClass = ' lg:text-sm opacity-1 duration-500 transition-all border-t-2  border-gray-100/60 pt-2 cursor-default';
  const contentShow = ' absolute opacity-0 cursor-default';


  return (
    <>
      <motion.div className=' relative shadow-md ' initial={{ y : 50, opacity : 0}} whileInView={{ y: 0, opacity : 1}} viewport={{ once: true }} transition={{ duration : 1}} whileHover={{ scale : 1.03}}>
        <div  className=' bg-gray-100 h-64 ' onMouseEnter={()=>changeShowContent(true)} onMouseLeave={()=>changeShowContent(false)}>
          <div className=' absolute bg-white rounded-full w-36 h-36 mt-9 flex items-center justify-center shadow-lg left-0 right-0 mx-auto'>
            <div style={{ backgroundImage : `url(${props.image})` }} className=' bg-center	bg-no-repeat bg-contain h-32 w-32'></div>
          </div>
          <motion.div className={showContent ? divClass  : divClassShow}>
            <div className=' text-xl justify-center font-light cursor-default'>
              <b>{props.title}</b>
            </div>
            <div className={showContent ? contentClass  : contentShow }>
              {props.content} 
            </div>
          </motion.div> 
        </div>
      </motion.div>
    </>
  )
}

export default Card