import React, { useState } from 'react'
import logoFix from '../../assets/img/logoWhite.png'
import logoScroll from '../../assets/img/logo.png'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


function MobileLink(props){
    const customIcon = "fa-solid fa-"+props.icon;
    return(
    <>
        <Link to={props.href}>
            <button onClick={props.handleButtonClick} className=" mt-2 w-full py-4 text-blue-900 text-base font-medium transition-all rounded duration-500 bg-gray-300">
                <i className={customIcon}></i>  {props.text} 
            </button>
        </Link>
    </>
    );
}


export default function HeaderMobile(props) {
    const [menuState , changeMenuState] = useState(false);
    const handleButtonClick = ()=>{
        !menuState ? changeMenuState(true) : changeMenuState(false);
    }

    const headerHome = 'fixed h-24 w-full flex items-center transition-all duration-500';
    const headerScroll = 'fixed h-20 w-full bg-gray-200 shadow-md flex items-center transition-all duration-500';

    const imageHome = 'h-20 w-28 flex items-center mx-6 transition-all duration-300';
    const imageScroll = 'h-18 w-24 flex items-center mx-6 transition-all duration-300';

    const classHome = "fa-solid fa-bars text-gray-100 text-xl hover:bg-gray-100/10 transition-all duration-300 px-3 py-2 rounded-full";
    const classScroll = "fa-solid fa-bars text-blue-900 text-xl hover:bg-gray-100/10 transition-all duration-300 px-3 py-2 rounded-full";

  return (
    <>
        <div className={props.scrollControl ? headerScroll : headerHome} >
            <motion.div initial={{ opacity : 0 , y: -50}} animate={{ opacity : 1, y : 0 }} transition={{ duration : 0.5 , delay: 0.5}} className=' flex-grow p-5'>
                <button onClick={ handleButtonClick }>
                    <i className={props.scrollControl ? classScroll : classHome}></i>
                </button>
            </motion.div>
            <motion.div  initial={{ opacity : 0 , y: -50}} animate={{ opacity : 1, y : 0 }} transition={{ duration : 0.5  , delay: 0.5}}>
            <a href="/">
                <div className={props.scrollControl ? imageScroll : imageHome}>
                    { props.scrollControl ? (
                        <img src={logoScroll} alt="" className='hover:grayscale transition-all duration-300 '/>
                    ): (
                        <img src={logoFix} alt="" className='hover:grayscale transition-all duration-300 '/>
                    )}
                </div>
            </a>
            </motion.div>
            <motion.div onClick={ handleButtonClick } initial={{ opacity : 0, x: -5000 }} animate={menuState ? { opacity : 1 , x:0 } : {}} exit={{ opacity : 0, x: -5000  }} transition={{ duration : 0}}
            className='fixed w-screen h-screen bg-stone-800/20 shadow-xl right-0 top-0'></motion.div>
            <motion.div  initial={{ opacity : 0, x: -300 }} animate={menuState ? { opacity : 1 , x:0 } : {}} exit={{ opacity : 0, x: -300  }} transition={{ duration : 0.5}}
             className='fixed w-64 h-screen bg-gray-200 shadow-xl left-0 top-0 px-2 py-4 flex flex-col justify-center'>
                <button className=' top-0 left-0 absolute px-5 py-4 mt-2 fa-solid fa-xmark text-xl text-white bg-blue-900 rounded-r-full' onClick={ handleButtonClick }></button>
                <MobileLink href="/" icon="house" text="HOME" handleButtonClick={handleButtonClick} />
                <MobileLink href="/Products" icon="boxes-stacked" text="PRODUCTS" handleButtonClick={handleButtonClick}/>
                <MobileLink href="/About" icon="question" text="ABOUT US" handleButtonClick={handleButtonClick}/>
                <MobileLink href="/Contact" icon="address-card" text="CONTACT US" handleButtonClick={handleButtonClick}/>
            </motion.div>
            
            
        </div>
    </>
  )
}
