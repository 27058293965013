import React from 'react'
import { useState } from 'react'
function Modal({productName, productTechURL}) {
    const [showModal, setShowModal] = useState(false);
    return (
      <>
        <button
          className=' px-4 py-2 text-xl text-white bg-slate-600 rounded-md mt-4 float-right'
          type="button"
          onClick={() => setShowModal(true)}
        >
          <i className="fa-solid fa-file-pdf"></i> See technichal details
        </button>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative mx-auto max-w-3xl w-full h-full py-10">
                {/*content*/}
                <div className="border-0 h-full rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">{productName}</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <i className="fa-solid fa-x text-black"></i>
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto w-full h-full">
                    <iframe title='technicalDetails' src={productTechURL} width="100%" height="100%" />
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
export default Modal