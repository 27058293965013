import React from 'react'
import Card from './Main/Card'
import { motion } from 'framer-motion';
import CarouselCustom from './Main/CarouselCustom'
import CardImage1 from '../assets/img/maintenance.png';
import CardImage2 from '../assets/img/equipment.png';
import CardImage3 from '../assets/img/service.png';
import StockImage from '../assets/img/stockImage.png';
import PeoplePlanningImage from '../assets/img/PeoplePlanningImage.jpg';

import AgramkowLogo from '../assets/img/Partners/AGRAMKOW.png';
import IrritrolLogo from '../assets/img/Partners/IRRITROL.png';
import NittoLogo from '../assets/img/Partners/NITTO.png';
import SkfLogo from '../assets/img/Partners/SKF.png';
import InficonLogo from '../assets/img/Partners/INFICON.png';
import RulmecaLogo from '../assets/img/Partners/RULMECA.png';
import SchunkLogo from '../assets/img/Partners/SCHUNK.png';
import eatonLogo from '../assets/img/Partners/EATON.png';
import TandDLogo from '../assets/img/Partners/TandD.png';

import ClientsMap from '../assets/img/ClientsMap.png';
import Footer from './Footer/Footer';
import { Link } from 'react-router-dom';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from 'react';
import { Helmet } from 'react-helmet';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function PartnerCard(props){

  const [hovered, setHovered] = useState(false);

  return(
      <div className='w-full h-52  flex justify-center items-center ' >
        <div onMouseEnter={()=> setHovered(true)} onMouseLeave={()=> setHovered(false)} className=' bg-white transition-all shadow-lg duration-300 w-40 h-40 mx-auto' style={{  borderRadius: hovered ? '1.5rem' : '100%' , padding : props.padding ?  '1rem' : '0rem', border : '1px solid rgb(156 163 175 / var(--tw-border-opacity))}' }}>
          <div className='w-full h-full bg-contain bg-no-repeat bg-center' style={{backgroundImage : `url(${props.Image})`}}></div>
        </div>
      </div>
  )
}

function MainFinal() {
  let boxVariantsLeft = {};
  let boxVariantsRight = {};
  const isMobile = window.innerWidth < 1024;
  if (!isMobile) {
    boxVariantsLeft = {
      initialAnimation : {opacity : 0, x: -100},
      toAnimate : { opacity : 1, x:0}
    };
    boxVariantsRight= {
      initialAnimation : {opacity : 0, x: 100},
      toAnimate : { opacity : 1, x:0}
    };
  }
  return (
    <>
      <Helmet>
            <title>A&F Technology</title>
            <meta name="description" content="We are a specialized industrial engineering company known for leading advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration products, and water treatment solutions. With extensive expertise and a commitment to excellence, we offer top-quality solutions at competitive prices. Our focus on innovation, technical prowess, and customer satisfaction sets us apart as a trusted global leader." />
        </Helmet>
      {/* CUSTOMED CARROUSEL */}
      <h1 className=' absolute opacity-0'>A&F Technology</h1>
      <CarouselCustom />
      {/* SERVICES DSPLAY */}
      <div className=' container max-w-screen-xl mx-auto p-4'>
        <h2 className=' text-center md:text-4xl font-normal text-blue-900 py-5 text-2xl'>Explore your next solution from A&F Techonology</h2>
        <div className='grid lg:grid-cols-5 lg:grid-rows-1 md:grid-cols-3 gap-4 my-4'>
          <div className=' lg:block hidden'></div>
          <Card image={CardImage1} title={"Maintenance"} content={"Our maintenance services are the backbone of our commitment to customer satisfaction. "}/>
          <Card image={CardImage2} title={"Equipment"} content={"Industry-leading solutions in diffrent fields that will bring your production to a higher level"}/>
          <Card image={CardImage3} title={"Support"} content={"Comprehensive service and support to ensure your equipment is operating at the optimal performance, all the time"}/>
          <div className=' lg:block hidden'></div>
        </div>
      </div>
      {/* FIRST DIPLAY */}
      <div className='w-full bg-gray-100 lg:my-4 py-7'>
        <div className=' container max-w-screen-xl mx-auto px-4 grid md:grid-cols-5 md:grid-rows-1 lg:gap-9'>
          <motion.div viewport={{ once: true }} className='lg:col-span-3 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsLeft}>
            <div className=' lg:h-full bg-slate-500/60 bg-center	bg-no-repeat bg-cover h-56' style={{ backgroundImage : `url(${PeoplePlanningImage})` }}></div>
          </motion.div>
          <motion.div viewport={{ once: true }} className='lg:col-span-2 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsRight}>
            <div className='lg:min-h-96 h-full bg-gray-400/60 p-10 text-gray-700'>
              <p className=' lg:text-4xl text-2xl'>Delivering Excellence :<br /> Our Commitment to Quality Services and Innovative Solutions</p><br />
              <p>Our services exemplify excellence at every step. Backed by expertise and advanced technology, we deliver tailored solutions that consistently exceed expectations and drive success.</p>
              <button className=' sm:mt-5'>
                <Link to='/Products' className='px-4 py-3 bg-gray-200 text-gray-700 rounded-sm  relative lg:-bottom-5 font-semibold float-right hover:bg-gray-500 hover:text-gray-200 duration-300'>See our Products</Link>
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      {/* SECOND DIPLAY */}
      {!isMobile ? (<div className='w-full lg:my-4 py-7'>
        <div className=' container max-w-screen-xl mx-auto px-4 grid md:grid-cols-5 md:grid-rows-1 lg:gap-9'>
          <motion.div viewport={{ once: true }} className='lg:col-span-2 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsLeft}>
            <div className='lg:min-h-96 h-full bg-gray-400/60 p-10 text-gray-100'>
              <p className=' lg:text-4xl text-2xl'>Global Reach :<br />Extending Our Services Worldwide</p><br />
              <p>From continents to industries, we bring tailored solutions and innovation to clients globally, ensuring consistent quality and impact across borders.</p>
              <button className=' sm:mt-5'>
                <Link to='/Products' className='px-4 py-3 bg-gray-200 text-gray-500 rounded-sm  relative lg:-bottom-5 font-semibold float-right hover:bg-gray-500 hover:text-gray-200 duration-300'>See our Products</Link>
              </button>
            </div>
          </motion.div>
          <motion.div viewport={{ once: true }} className='lg:col-span-3 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsRight}>
            <div className=' lg:h-full bg-slate-500/60 bg-center	bg-no-repeat bg-cover h-56' style={{ backgroundImage : `url(${StockImage})` }}></div>
          </motion.div>
        </div>
      </div>) 
      : 
      (<div className='w-full lg:my-4 py-7'>
        <div className=' container max-w-screen-xl mx-auto px-4 grid md:grid-cols-5 md:grid-rows-1 lg:gap-9'>
          <motion.div viewport={{ once: true }} className='lg:col-span-3 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsRight}>
            <div className=' lg:h-full bg-slate-500/60 bg-center	bg-no-repeat bg-cover h-56' style={{ backgroundImage : `url(${StockImage})` }}></div>
          </motion.div>
          <motion.div viewport={{ once: true }} className='lg:col-span-2 md:col-span-5' initial="initialAnimation" whileInView="toAnimate" transition={{ duration : 1}} variants={boxVariantsLeft}>
            <div className='lg:min-h-96 h-full bg-gray-400/60 p-10 text-gray-100'>
              <p className=' lg:text-4xl text-2xl'>Global Reach :<br />Extending Our Services Worldwide</p><br />
              <p>From continents to industries, we bring tailored solutions and innovation to clients globally, ensuring consistent quality and impact across borders.</p>
              <button className=' sm:mt-5'>
                <Link to='/Products' className='px-4 py-3 bg-gray-200 text-gray-500 rounded-sm  relative lg:-bottom-5 font-semibold float-right hover:bg-gray-500 hover:text-gray-200 duration-300'>See our Products</Link>
              </button>
            </div>
          </motion.div>
        </div>
      </div>)}
      {/* PARTNERS */}
      <div className=' w-full bg-gray-100 lg:my-4 py-7 '>
        <div className='container max-w-screen-xl mx-auto px-4'>
          <h2 className='text-center md:text-4xl font-normal text-blue-900 text-3xl'>Our Partners</h2>  
            <motion.div viewport={{ once: true }} initial={{opacity : 0, y: -20}} whileInView={{ opacity : 1, y:0}} transition={{ duration : 1.5}}>
              <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000} infinite={true} removeArrowOnDeviceType={["tablet", "desktop"]}>
                <PartnerCard padding={true} Image={NittoLogo} />
                <PartnerCard padding={true} Image={SkfLogo} />
                <PartnerCard padding={true} Image={AgramkowLogo} />
                <PartnerCard padding={true} Image={InficonLogo} />
                <PartnerCard padding={true} Image={IrritrolLogo} />
                <PartnerCard padding={true} Image={eatonLogo} />
                {/*<PartnerCard padding={true} Image={RulmecaLogo} />*/}
                <PartnerCard padding={false} Image={TandDLogo} />
                <PartnerCard padding={true} Image={SchunkLogo} />
              </Carousel>
            </motion.div>
        </div>
      </div>
      {/* Clients Map*/}
      <div className='w-full lg:my-4'>
        <div className=' container max-w-screen-xl mx-auto px-4'>
          <h2 className=' text-center md:text-4xl font-normal text-blue-900 py-5 text-xl'>We offer our Services Worldwide</h2>
          <h6 className=' text-center font-normal text-gray-900'>*We already have customers in these countries</h6>
          <div className=' container mx-auto min-h-fit bg-no-repeat bg-center bg-contain' style={{ backgroundImage : `url(${ClientsMap})` }}>
            <img className=' opacity-0 -z-50 selector' src={ClientsMap} alt="Client Map" />
          </div>
        </div>
      </div>
      {/* PARTNERS */}
      <div className=' w-full bg-blue-900 lg:mt-4 py-20 '>
        <div className='container max-w-screen-xl mx-auto px-4 flex flex-col items-center content-center'>
          <h2 className='text-center md:text-4xl font-normal text-gray-100 text-xl'>Are you ready to see what we can do together ?</h2><br /><br />
          <button>
                <Link to='/Contact' className=' font-semibold px-4 py-3 bg-slate-200 text-blue-9 00 rounded-sm hover:bg-blue-800 hover:text-slate-200 duration-300'>Contact us now</Link>
          </button>
        </div>
      </div>
      {/* FOOTER */}
      <Footer/>
    </>
  )
}

export default MainFinal