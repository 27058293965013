import React from 'react'
import { useState } from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';

function ProductCard({title, href, img}) {
    const [onHover, handleOnHover] = useState(false);

    const boxClass = ' h-16 rounded-b-sm text-white flex border-2 border-b-0 bg-blue-900 border-blue-900 items-center px-5 transition-all translate-y-10 duration-500';
    const boxClassHover = ' h-16 bg-gray-100 rounded-t-sm border-2 flex items-center border-b-0 text-blue-900 border-blue-900 px-5 transition-all duration-500 -translate-y-0';

    const buttonClass = ' px-4 text-white bg-blue-900 py-2 font-medium text-center rounded-sm transition-all duration-700  bottom-0 min-w-full';
    const buttonClassHover =' text-white px-4 py-2 font-medium text-center bg-blue-900 rounded-b-sm transition-all duration-700 bottom-0 min-w-full';

  return (
    <>
    <motion.div initial={{ opacity : 0 , y : 20 }} whileInView={{ opacity : 1 , y:0}} viewport={{ once: true }} transition={{ duration : 1 }}>
        <Link to={href} onMouseEnter={()=>handleOnHover(true)} onMouseLeave={()=>handleOnHover(false)}>
            <div className=' w-full min-h-fit bg-white shadow-lg flex flex-col rounded-sm hover:scale-105 transition-all duration-300'>
                <div className=' flex-grow h-60 bg-center bg-no-repeat bg-contain rounded-t-sm' style={{ backgroundImage : `url(${img})` }}></div>
                <div className={onHover ? boxClassHover : boxClass}>
                    <h3 className=' font-semibold flex-grow text-xl text-center '>{title}</h3>
                </div>
                <div>                    
                    <button className={onHover ? buttonClassHover : buttonClass}>Go to product</button>
                </div>
            </div>
        </Link>
    </motion.div>
    </>
  )
}

export default ProductCard