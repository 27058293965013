import React , {useEffect , useState , useContext } from "react";
import {FirebaseContext} from './index';
import AuthProvider from "./AuthProvider";



function AuthContext ({ children }) {
    const [user, setUser] = useState(null);
    const firebase = useContext(FirebaseContext);
    useEffect(()=>{
        const subscriber = firebase.onAuthStateChanged((userFound)=>{
          setUser(userFound);
        })
        return ()=>{subscriber()};
        // eslint-disable-next-line
    },[])
    return (
        <AuthProvider.Provider value={user}>
            {children}
        </AuthProvider.Provider>
  )
}

export default AuthContext