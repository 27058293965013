import React from 'react'
import Footer from './Footer/Footer'
import ProductCard from './Products/ProductCard'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import Loader from '../assets/img/LoaderBlue.svg';
import { Helmet } from 'react-helmet';

function Lubrification() {
  const firebase = useContext(FirebaseContext);

  const [data, setData] = useState(null)
  const [dataState, setDataState] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(()=>{
    setDataState(false);
      setData(null);
      async function fetchData() {
          const queryResult = await firebase.getProductType("Lubrification");
          setData(queryResult);
      }
      fetchData()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(data !== null && products !==[])
      setDataState(true)
    else      
      setDataState(false)
      // eslint-disable-next-line
  },[data])


  useEffect(()=>{
    if(data != null){
      data.docs.map(async (doc)=>{
        const storageRef = firebase.ref(doc.data().product_img)
        const imageLink = await firebase.getDownloadURL(storageRef)
        setProducts((prev)=>[...prev,{
          id : doc.id,
          product_name : doc.data().product_name,
          product_img : imageLink
        }])
      })
    }
    // eslint-disable-next-line
  },[data])
  const heightAfter = 'container max-w-screen-xl mx-auto p-4 my-4 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 grid-cols-1';
  const heightBefore = 'container max-w-screen-xl h-full mx-auto p-4 my-4 flex items-center jutify-center';
  return (
    <>
        <Helmet>
            <title>A&F Technology | Lubrification</title>
            <meta name="description" content="We are a specialized industrial engineering company known for leading lubrication systems." />
        </Helmet>
    <div className='min-h-screen'>
        {/*TopPage*/}
        <div className=' h-96 bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white'>
            <div className='text-center items-center justify-center flex flex-col h-full pt-10'>
                <h1 className=' lg:text-7xl md:text-5xl font-semibold text-3xl'>Our solutions in Lubrification</h1>
                <h3 className=' md:text-2xl mt-6 font-thin ' >The perfect equipments brought to you by our trusted Partners</h3>
            </div>
        </div>
        {/*PageContent*/}
        <div className={dataState ? heightAfter : heightBefore}>
          { dataState? products.map((product)=>{
              return <ProductCard key={product.id} title={product.product_name} href={'/Lubrification/'+product.id} img={product.product_img}/>
            })
          : <div className='bg-gradient-to-b flex flex-col items-center justify-center w-full h-full'>
              <img src={Loader} width='200' alt="Loading" />
            </div> }
        </div>
    </div>
        {/* FOOTER */}
        <Footer/>
    </>
  )
}

export default Lubrification