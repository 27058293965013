import React , {useContext , useEffect , useState} from 'react'
import { FirebaseContext } from '../../Firebase';

function AdminContact() {
    const firebase = useContext(FirebaseContext);

    const [dataState, setDataState]  = useState(false);
    const [dataFound, setData] = useState(null);

    useEffect(()=>{
        async function fetchData() {
            const queryResult = await firebase.getAll("contact");
            setData(queryResult);
        }
        fetchData()
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(dataFound !== null && dataFound !== undefined){
            setDataState(true);
        }
        else
            setDataState(false);
    },[dataFound])

    const handleClick = async (e)=>{
        await firebase.deleteContactWithID(e.target.id)
        window.location.reload(false)
    }
    return (
        <>
        <h1 className="text-xl font-semibold text-gray-900 sm:text-4xl m-auto text-center">Contact Requests</h1>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 grid-cols-1'>
            { dataState ? dataFound.docs.map((doc)=>{
                return (
                <div key={doc.id} className=" p-6 bg-white border border-gray-200 rounded-lg shadow mt-5 m-auto w-full">
                    <button id={doc.id} onClick={handleClick} className=' text-white bg-red-600 px-4 py-3 rounded-md float-right'><i className="fa-solid fa-trash-can"></i></button>
                    <h3><span className=' text-blue-900 font-semibold'>Name : </span>{doc.data().sender_name}</h3>
                    <h3><span className=' text-blue-900 font-semibold'>Email : </span>{doc.data().sender_email}</h3>
                    <h3><span className=' text-blue-900 font-semibold'>Company : </span>{doc.data().sender_company}</h3>
                    <h3><span className=' text-blue-900 font-semibold'>Country : </span>{doc.data().sender_country}</h3>
                    <h3><span className=' text-blue-900 font-semibold'>Phone : </span>{doc.data().sender_phone}</h3>
                    <h3><span className=' text-blue-900 font-semibold'>Message :</span></h3>
                    <p>{doc.data().sender_message}</p>
                </div>)
            }) : null}
        </div>
        </>
    )
}

export default AdminContact