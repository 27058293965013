import React , {useState} from 'react'
import AddProduct from './AddProduct'
import ManageProducts from './ManageProducts'

function AdminProducts() {
  const [done ,setDone] = useState(false);
  return (
    <>
        <h1 className="text-xl font-semibold text-gray-900 sm:text-4xl m-auto text-center">Products</h1>
        <div className=' sm:flex gap-5'>
            <AddProduct done={done} setDone={setDone} />
            <ManageProducts done={done} />
        </div>
    </>
  )
}

export default AdminProducts