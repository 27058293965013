import { Route, Routes } from "react-router-dom";
import Appliance from "./compontents/Appliance";
import Contact from "./compontents/Contact";
import HeaderFinal from "./compontents/HeaderFinal";
import Lubrification from "./compontents/Lubrification";
import MainFinal from "./compontents/MainFinal";
import ProductsHome from "./compontents/ProductsHome";
import ShowProductAppliance from "./compontents/ShowProductAppliance";
import ShowProductLubrification from "./compontents/ShowProductLubrification";
import Filtration from "./compontents/Filtration";
import ShowProductFiltration from "./compontents/ShowProductFiltration";
import Watering from "./compontents/Watering";
import ShowProductWatering from "./compontents/ShowProductWatering";
import ErrorPage from "./compontents/ErrorPage";
import Login from "./compontents/Login/Login";
import Admin from "./compontents/Admin/Admin";
import AuthContext from "./Firebase/AuthContext";
import AdminContact from "./compontents/Admin/AdminContact";
import AdminProducts from "./compontents/Admin/AdminProducts";
import About from "./compontents/About";



function App() {
  const filtredLinks = ['/LoginAdmin','/AdminHome','/AdminProducts','/AdminContact'];
  return (
    <>
      <header>
        {!filtredLinks.includes(window.location.pathname)  ? <HeaderFinal/> :null}
      </header>
      <main>
        <Routes>
          <Route path="/" element={<MainFinal />}/>
          <Route path="/Products" element={<ProductsHome />} />
          <Route path="/Appliance" element={<Appliance />} />
          <Route path="/Appliance/:id" element={<ShowProductAppliance />} />
          <Route path="/Lubrification" element={<Lubrification />} />
          <Route path="/Lubrification/:id" element={<ShowProductLubrification />} />
          <Route path="/Filtration" element={<Filtration />} />
          <Route path="/Filtration/:id" element={<ShowProductFiltration />} />
          <Route path="/Watering" element={<Watering />} />
          <Route path="/Watering/:id" element={<ShowProductWatering />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />

          {/* ADMIN */}

          <Route path="/LoginAdmin" element={<Login />}/>
          <Route path="/AdminHome" element={<AuthContext><Admin><AdminProducts /></Admin></AuthContext>} />
          <Route path="/AdminContact" element={<AuthContext><Admin><AdminContact /></Admin></AuthContext>} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
