import React, { useState , useContext , useEffect } from 'react'
import AdminHeaderDesktop from './AdminHeader/AdminHeaderDesktop'
import Loader from '../../assets/img/Loader.svg';
import AuthProvider from '../../Firebase/AuthProvider';
import { useNavigate } from "react-router-dom";

function Admin( {children} ) {

  const user = useContext(AuthProvider);
  const [timerID, setTimerID] = useState(null);
  const navigate = useNavigate();

  useEffect(()=>{
    if(user !== null){
      clearTimeout(timerID);
    }else{
      const timer = setTimeout(()=>{
          navigate("/LoginAdmin");
      },5000);
      setTimerID(timer);
    }
    // eslint-disable-next-line
  },[user])
  

  return user===null ? (
    <>
      <div className=' h-screen w-screen bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white flex flex-col items-center justify-center'>
        <img src={Loader} width='200' alt="Loading" />
      </div>
    </>
  ): (
    <>
      <AdminHeaderDesktop />
      <div className="sm:ml-64 bg-gray-100 min-h-screen">
        <div className="mx-4 p-4 border-gray-200 sm:border-0 border-t-2 ">
          {children}
        </div>
      </div>
    </>
  )
}

export default Admin