import { initializeApp } from "firebase/app";
import {getAuth,signInWithEmailAndPassword ,signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore , collection , getDocs, getDoc , addDoc, onSnapshot, query , where, doc, deleteDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
  };

class Firebase{
    constructor(){
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth();
        this.db = getFirestore(this.app);
        this.storage = getStorage(this.app);
    }
    //Connexion
    loginUser = (email, password) => signInWithEmailAndPassword(this.auth,email,password)
    //Deconnexion
    signOut = () => signOut(this.auth)
    //GetAutheticatedUser
    onAuthStateChanged = (functionToExecute) => onAuthStateChanged(this.auth, functionToExecute)
    //Add Contact Message
    addContactMessage = (senderName,senderCompany,senderCountry,senderEmail,senderPhone,senderMessage) => addDoc(collection(this.db, "contact"),{
        sender_name : senderName,
        sender_company : senderCompany,
        sender_country : senderCountry,
        sender_email : senderEmail,
        sender_phone : senderPhone,
        sender_message : senderMessage
    })
    //Add Product
    addProduct = (productName, productDesc, productCategory, productImg, productTechnical) => addDoc(collection(this.db, "products"),{
        product_name : productName,
        product_desc : productDesc,
        product_cat : productCategory,
        product_img: productImg,
        product_tech: productTechnical
    })
    //Upload Image
    uploadImage = (imageName)=> ref(this.storage, imageName)
    uploadBytes = (imageRef, image) => uploadBytes(imageRef, image)
    //GET ALL DOCS
    getAll = (collectionName)=> getDocs(collection(this.db, collectionName))
    //GET DOCS WITH CONDITION
    getProductType = (productType) => getDocs(query(collection(this.db,"products"), where("product_cat", "==", productType)))
    //GET QUERY
    getQuery = (collectionName)=> query(collection(this.db, collectionName))
    //SnapShot
    onSnapshot = (q, functionToExecute) => onSnapshot(q, functionToExecute)
    //GETDOC WITH ID
    getDoc = (id) => getDoc(doc(this.db,"products",id))
    //DELETE DATA FROM FIRESTORE
    deleteFile = (fileName) => deleteObject(ref(this.storage, fileName))
    //DELETE DOC WITH ID
    deleteDocWithID = (docID) => deleteDoc(doc(this.db,"products",docID))
    //DELETE CONTACT WITH ID
    deleteContactWithID = (docID) => deleteDoc(doc(this.db,"contact",docID))
    //GET FILE REF
    ref = (filePath) => ref(this.storage,filePath)
    //GET DOWNLOAD URL
    getDownloadURL = (fileRef) => getDownloadURL(fileRef)

}

export default Firebase;