import React from 'react'
import Footer from './Footer/Footer'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { useNavigate } from "react-router-dom";
import Loader from '../assets/img/Loader.svg';
import Modal from './Products/Modal';
import { Helmet } from 'react-helmet';

function ShowProductWatering() {
  const firebase = useContext(FirebaseContext);
  const [data, setData] = useState(null);
  const [dataFound , setDataFound] = useState(false);
  const [dataNotExisting , setDataNotExisting] = useState(false);
  const productID = window.location.pathname.split('/')[2];
  const navigate = useNavigate();

  const [imageURL, setImageURL] = useState('');
  const [techURL, setTechURL] = useState('');

  useEffect(()=>{
    async function fetchData() {
      const productFound = await firebase.getDoc(productID);
      setData(productFound);
    }
    fetchData()
    
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(data !== null){
      if(data._document === null){
        setDataNotExisting(true)
      }else{
        setDataFound(true)
        const imageRef = firebase.ref(data.data().product_img)
        const techRef = firebase.ref(data.data().product_tech)
        async function getImageURL() {
          const imageURL = await firebase.getDownloadURL(imageRef)
          setImageURL(imageURL)
        }
        async function getTechURL() {
          const techURL = await firebase.getDownloadURL(techRef)
          setTechURL(techURL)
        }
        getImageURL()
        getTechURL()
      }
    }
    // eslint-disable-next-line
  },[data])

    useEffect(()=>{
      if(dataNotExisting === true)
        navigate("/Error");
        // eslint-disable-next-line
    },[dataNotExisting])

  return (
    <>
      {dataFound ? <>
         <Helmet>
              <title>A&F Technology | {data.data().product_name}</title>
              <meta name="description" content={data.data().product_desc} />
          </Helmet>
          {/*TopPage*/}
          <div className=' h-96 bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white'>
              <div className='text-center items-center justify-center flex flex-col h-full pt-10'>
                  <h1 className=' lg:text-7xl md:text-5xl font-semibold text-3xl'>{data.data().product_name}</h1>
              </div>
          </div>
          {/*PageContent*/}
          <div className=' container max-w-screen-xl mx-auto p-4 my-4 grid lg:grid-cols-4 md:grid-cols-2 gap-5 grid-cols-1 '>
              <div className='lg:col-span-2 col-span-1 h-96 min-w-full bg-no-repeat bg-center bg-contain' style={{ backgroundImage : `url(${imageURL})` }}></div>
              <div className='lg:col-span-2 col-span-1'>
                <h2 className=' text-2xl font-bold text-blue-900'>{data.data().product_name}</h2>
                <p className='mt-4'>{data.data().product_desc}</p>
                <Modal productName={data.data().product_name} productTechURL={techURL}/>
              </div>        
          </div>
          {/* FOOTER */}
          <Footer/>
        
      </> : <div className=' h-screen w-screen bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white flex flex-col items-center justify-center'>
              <img src={Loader} width='200' alt="Loading" />
            </div>}
    </>
  )
}
export default ShowProductWatering