import React from 'react'
import { useState } from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';

function ProductTypeCardV2({title, href, img}) {
    const [onHover, handleOnHover] = useState(false);

    const shadowImage = 'rounded-sm bg-black/50 h-full w-full transition-all duration-300';
    const shadowImageHover = ' rounded-sm bg-black/0 h-full w-full transition-all duration-300';

    const boxClass = ' h-64 lg:w-64 w-full bg-white/90 rounded-r-sm text-blue-900 flex flex-col items-center justify-center p-5 transition-all duration-500 gap-6';
    const boxClassHover = ' h-64 lg:w-64 w-full bg-white rounded-r-sm text-blue-900 flex flex-col items-center justify-center p-5 transition-all duration-500 gap-6';



  return (
    <>
    <motion.div initial={{ opacity : 0 , y : 20 }} animate={{ opacity : 1 , y:0}} transition={{ duration : 1 }} viewport={{ once: true }} className='sm:col-span-2 col-span-1'>
        <Link to={href} onMouseEnter={()=>handleOnHover(true)} onMouseLeave={()=>handleOnHover(false)}>
            <div className=' w-full min-h-fit bg-white shadow-lg flex flex-row rounded-sm bg-center bg-no-repeat  bg-cover hover:scale-105 transition-all duration-300' style={{ backgroundImage : `url(${img})` }}>
                <div className=' lg:flex-grow lg:w-auto w-full h-64  rounded-sm' >
                    <div className={onHover ? shadowImageHover : shadowImage }></div>
                </div>
                <div className={onHover ? boxClassHover : boxClass}>
                    <h3 className=' font-semibold lg:text-xl text-base text-center'>{title}</h3>
                    <button className=' bg-blue-900 text-white px-4 py-2 font-medium rounded-sm transition-all duration-500'>See Products</button>
                </div>
            </div>
        </Link>
    </motion.div>
    </>
  )
}

export default ProductTypeCardV2