import React from 'react'
import { useState , useContext } from 'react'
import logoScroll from '../../assets/img/logo.png'
import { FirebaseContext } from '../../Firebase'
import { useNavigate } from "react-router-dom";

function Login() {
    const firebase = useContext(FirebaseContext);
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const handleEmail = (e)=>{
        setEmail(e.target.value);
    }
    const handlePassword = (e)=>{
        setPassword(e.target.value);
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        firebase.loginUser(email,password)
        .then(user =>{
            setEmail('');
            setPassword('');
            setError(false);
            navigate("/AdminHome");
        })
        .catch(error =>{
            setError(true);
            setEmail('');
            setPassword('');
        })
    }
  return (
    <>
        <div className=' h-screen w-screen bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white flex items-center justify-center'>
            <div className=' bg-white shadow-lg w-96 rounded-sm flex flex-col items-center p-6 '>
                <a href="/">
                    <img src={logoScroll} className=" w-24" alt='logo' />
                </a>
                <form onSubmit={handleSubmit} className='w-full'>
                    {error ? <div className=' mt-5 text-center bg-red-600 p-2 rounded-sm'>Incorrect e-mail or password please try again</div> : null }
                    <div className=' mt-5 w-full'>
                        <label for="helper-email" class="block mb-2 text-sm font-medium text-gray-600 ">Admin Email :</label>
                        <input value={email} onChange={handleEmail} type="email" id="helper-email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="mail@email.com" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-passwod" class="block mb-2 text-sm font-medium text-gray-600 ">Admin Password :</label>
                        <input value={password} onChange={handlePassword} type="password" id="helper-passwod" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="*********" required />
                    </div>
                    <button className='mt-5 bg-blue-900 w-full py-4 rounded-sm font-semibold' type='submit'>Log In</button>
                </form>

            </div>
        </div>
    </>
  )
}

export default Login