import React , {useContext} from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FirebaseContext } from '../../Firebase';


function TableItem(props){
    const firebase = useContext(FirebaseContext);
    


    const deleteProduct = async (e)=>{
        const docSnap  = await firebase.getDoc(e.target.id)
        // eslint-disable-next-line
        {/*DELETE PRODUCT IMAGE*/}
        firebase.deleteFile(docSnap.data().product_img)
        .then(()=>{
            props.setError(false);
        }).catch((e)=>{
            props.setError(true);
        })
        // eslint-disable-next-line
        {/*DELETE TECHNICAL DETAILS FILE*/}
        if(!props.error){
            firebase.deleteFile(docSnap.data().product_tech)
            .then(()=>{
                props.setError(false);
            }).catch((e)=>{
                props.setError(true);
            })
        }
        // eslint-disable-next-line
        {/*DELETE THE PRODUCT INFOS*/}
        if(!props.error){
            try{
                console.log('dfvfd')
                await firebase.deleteDocWithID(e.target.id)
                console.log('lalalal')            
                props.setSucess(true)
                props.setError(false)    
            }catch (e){
                console.log(e)
                props.setSucess(false)
                props.setError(true)
            }
        }
    }
    useEffect(()=>{
        const timerID = setTimeout(()=>{
            props.setError(false);
        },5000)
        if(!props.error){
            clearTimeout(timerID);
        }
        // eslint-disable-next-line
    },[props.error])

    useEffect(()=>{
        const timerID = setTimeout(()=>{
            props.setSucess(false);
        },5000)
        if(!props.sucess){
            clearTimeout(timerID);
        }
        // eslint-disable-next-line
    },[props.sucess])
    return(
        <tr className="bg-white border-b ">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                {props.name}
            </th>
            <td className="px-6 py-4">
                {props.desc}
            </td>
            <td className="px-6 py-4">
                {props.category}
            </td>
            <td className="px-6 py-4">
                {/*WHEN YOU DELETE YOU DELETE ALSO THE IMAGE AND THE PDF FILE FROM THE FIREBASE STORAGE*/}
                <button onClick={deleteProduct} id={props.id} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 w-full"><i className="fa-solid fa-trash-can"></i> Delete</button>
            </td>
        </tr>
    );
}

function ManageProducts({done}) {
    const firebase = useContext(FirebaseContext);

    const [dataState, setDataState]  = useState(false);
    const [dataFound, setData] = useState(null);

    const [error , setError] = useState(false);
    const [sucess , setSucess] = useState(false);

    useEffect(()=>{
        async function fetchData() {
            const queryResult = await firebase.getAll("products");
            setData(queryResult);
        }
        fetchData()
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(done||sucess){
            async function fetchData() {
                const queryResult = await firebase.getAll("products");
                setData(queryResult);
            }
            fetchData()
        }
        // eslint-disable-next-line
    },[done,sucess])

    useEffect(()=>{
        if(dataFound != null){
            setDataState(true);
        }
        else
            setDataState(false);
    },[dataFound])

    const handleAppliance = (e)=>{
        setDataState(false);
        setData(null);
        e.preventDefault();
        async function fetchData() {
            const queryResult = await firebase.getProductType("Appliance");
            setData(queryResult);
        }
        fetchData()
    }
    const handleLubrification = (e)=>{
        setDataState(false);
        setData(null);
        e.preventDefault();
        async function fetchData() {
            const queryResult = await firebase.getProductType("Lubrification");
            setData(queryResult);
        }
        fetchData()
    }
    const handleFiltration = (e)=>{
        setDataState(false);
        setData(null);
        e.preventDefault();
        async function fetchData() {
            const queryResult = await firebase.getProductType("Filtration");
            setData(queryResult);
        }
        fetchData()
    }
    const handleWatering = (e)=>{
        setDataState(false);
        setData(null);
        e.preventDefault();
        async function fetchData() {
            const queryResult = await firebase.getProductType("Watering");
            setData(queryResult);
        }
        fetchData()
    }

    return (
        <>
            <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow mt-5 m-auto w-full">
                <h1 className="text-xl font-semibold text-gray-700 sm:text-2xl mb-5">Manage Products</h1>
                {sucess ? <div className=' mt-5 text-center bg-green-600 p-2 rounded-sm text-white mb-6'>Product deleted sucessfully !</div> : null }
                {error ? <div className=' mt-5 text-center bg-red-600 p-2 rounded-sm text-white mb-6'>An error has occurred while deleting the product !</div> : null }
                <div className='sm:flex gap-5 mb-6 '>
                    <button onClick={handleAppliance} className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 w-full my-2">Appliance Production</button>
                    <button onClick={handleLubrification} className="focus:outline-none text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 w-full my-2">Lubrification</button>
                    <button onClick={handleFiltration} className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 w-full my-2">Filtration</button>
                    <button onClick={handleWatering} className="focus:outline-none text-white bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 w-full my-2">Watering System</button>
                </div>
                <div className="relative overflow-x-auto max-h-screen">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Product name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Product description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Category
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { dataState? dataFound.docs.map( (doc)=>{
                                            return <TableItem error={error} setError={setError} sucess={sucess} setSucess={setSucess} name={doc.data().product_name} desc={doc.data().product_desc} category={doc.data().product_cat} id={doc.id} key={doc.id} />
                                        })
                            : null }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ManageProducts