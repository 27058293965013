import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { motion } from 'framer-motion';
import HomeImage1 from '../../assets/img/HomeCarrouselImage1.jpg';
import HomeImage2 from '../../assets/img/HomeCarrouselImage2.jpg';
import HomeImage3 from '../../assets/img/HomeCarrouselImage3.jpg';
import HomeImage4 from '../../assets/img/HomeCarrouselImage4.jpg';
import { Link } from 'react-router-dom';


function CarouselItem(props){
  const ClassDefault = ' w-screen h-full text-3xl bg-center	bg-no-repeat bg-cover bg-fixed ' +props.bgColor;
  return(
    <>
      <div style={{ backgroundImage : `url(${props.bgImage})` }}  className={ClassDefault}> 
          <div className=' bg-black/50 w-full h-80 flex flex-col items-center justify-center text-white lg:px-96 md:px-52 px-4 sm:py-80 py-64'>
              <div className=' max-w-2xl text-left border-l-2 border-white px-5 md:text-4xl font-semibold text-2xl'> 
                {props.Title}
              </div>
              <div className=' text-left py-5 md:text-2xl font-light text-lg '> 
                {props.SubTitle}
              </div>
              <div className=' max-w-xl'> 
                <button className=' bg-slate-100/80 text-blue-900 px-5 py-4 rounded-sm font-medium md:text-xl hover:cursor-pointer shadow-lg text-base'>Learn More<i className="fa-solid fa-plus ml-2"></i></button>
              </div>
          </div>
      </div>
    </>
  );
}

function CarouselCustom() {
  return (
    <>
    <motion.div initial={{opacity : 0, y: -20}} animate={{ opacity : 1, y:0}} transition={{ duration : 1}}>
      <div className=' h-full '>
        {/*
        <div className=' absolute bottom-0 w-full flex flex-col items-center justify-center text-gray-100 py-10'>
          <div className=' text-2xl border-b-2'>Scroll Down</div>
          <div className='my-4 animate-bounce text-xl'><i className="fa-sharp fa-solid fa-arrow-down"></i></div>
        </div>
        */}
        <Link to="/Products" >      
          <Carousel animationHandler="fade" transitionTime={700} showStatus={false} autoPlay={true} infiniteLoop={true} emulateTouch={false} stopOnHover={false} showIndicators={false} showThumbs={false} interval={3500} swipeable={false} showArrows={false}>
            <div className=" h-full w-full">
              <CarouselItem bgColor={"bg-slate-800"} Title={"Solutions for your smart factory"} SubTitle={"Our smart factory solutions embrace technology and efficiency. From automation systems to data-driven insights, we enhance productivity and informed decision-making for a seamless transition to Industry 4.0"} bgImage={HomeImage1} />
            </div>
            <div className=" h-full w-full">
              <CarouselItem bgColor={"bg-slate-600"} Title={"Lubrification solutions"} SubTitle={"Our lubrication solutions epitomize years of expertise and innovation. From high-pressure pumps to centralized systems, we offer tailored strategies that minimize friction, enhance efficiency, and extend machinery life, catering to diverse industrial needs."} bgImage={HomeImage2} />
            </div>
            <div className=" h-full w-full">
              <CarouselItem bgColor={"bg-slate-400"} Title={"Filtration solutions"} SubTitle={"Our filtration solutions exemplify precision and reliability. From hydraulic filters to water treatment systems, we optimize operations by reducing contaminants and enhancing fluid quality, ensuring peak performance and sustainability."} bgImage={HomeImage3}/>
            </div>
            <div className=" h-full w-full">
              <CarouselItem bgColor={"bg-slate-200"} Title={"Watering solutions"} SubTitle={"Our water treatment solutions prioritize purity and efficiency. From potable water filters to advanced wastewater systems, we optimize water quality, enhance sustainability, and promote responsible resource management."} bgImage={HomeImage4}/>
            </div>
          </Carousel>
        </Link>
      </div>
    </motion.div>
    </>
  )
}

export default CarouselCustom