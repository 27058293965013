import React from 'react'

function ErrorPage() {
  return (
    <>
        <div className=' h-screen w-screen bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white flex flex-col items-center justify-center'>
            <h1 className=' text-8xl font-semibold text-center'>404 - Not Found</h1> 
            <h3 className=' text-2xl text-center' >Sorry, this page doesn't exist.</h3>
        </div>
    </>
  )
}

export default ErrorPage