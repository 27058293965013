import React, {useState , useContext} from 'react'
import Footer from './Footer/Footer'
import { FirebaseContext } from '../Firebase';
import { Helmet } from 'react-helmet';
function Contact() {
    const firebase = useContext(FirebaseContext);

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [result, setResult] = useState("");

    const [pending, setPending] = useState(false);
    const [sucess, setSucess] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e)=>{
        e.preventDefault()
        setPending(true)
        setSucess(false)
        setError(false)
        setResult("Sending....");
        const formData = new FormData(e.target);

        formData.append("access_key", "5f8ca668-b669-4e52-9d08-019ab9fb1ca9");
        formData.append("Name", name);
        formData.append("Email", email);
        formData.append("Company", company);
        formData.append("Country", country);
        formData.append("Phone", phone);
        formData.append("Message", message);


        const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST", 
        body: formData
        }).then((res) => res.json());

        if (res.success) {
            setResult(res.message);

            
            await firebase.addContactMessage(name,company,country,email,phone,message);
            
            setName('')
            setCompany('')
            setCountry('')
            setEmail('')
            setMessage('')
            setPhone('')
            
            setSucess(true)
            setPending(false)
            setError(false)
            
            setTimeout(()=>{
                setResult("")
                setSucess(false)
            },5000)
        } else {
            setResult(res.message);

            setSucess(false)
            setPending(false)
            setError(true)

            setTimeout(()=>{
                setResult("")
                setError(false)
            },5000)
        }
    };
  return (
    <>
        <Helmet>
            <title>A&F Technology | Contact</title>
            <meta name="description" content="We are a specialized industrial engineering company known for leading advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration products, and water treatment solutions. With extensive expertise and a commitment to excellence, we offer top-quality solutions at competitive prices. Our focus on innovation, technical prowess, and customer satisfaction sets us apart as a trusted global leader." />
        </Helmet>
        {/*TopPage*/}
        <div className=' h-96 bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white'>
            <div className='text-center items-center justify-center flex flex-col h-full pt-10'>
                <h1 className=' lg:text-7xl md:text-5xl font-semibold text-3xl'>Get in touch</h1>
                <h3 className=' md:text-2xl mt-6 font-thin ' >Have any questions ? We'd love to hear from you.</h3>
            </div>
        </div>
        {/*PageContent*/}
        <div className=' container max-w-screen-xl mx-auto p-4 my-4 grid lg:grid-cols-2 gap-5 grid-cols-1'>
            {/*ContactForm*/}
            <div className='col-span-1'>
                <h2 className=' text-blue-900 font-normal text-3xl text-center mt-4'>Contact Form</h2>
                <form onSubmit={handleSubmit}>
                    {sucess ? <div className=' my-3 text-center bg-green-600/70 p-2 rounded-md text-white'>{result}</div> : null }
                    {pending ? <div className=' my-3 text-center bg-yellow-600/70 p-2 rounded-md text-white'>{result}</div> : null }
                    {error ? <div className=' my-3 text-center bg-red-600/70 p-2 rounded-md text-white'>{result}</div> : null }
                    <div className=' w-full'>
                        <label for="helper-name" class="block mb-2 text-sm font-normal text-gray-600 ">Full name :</label>
                        <input value={name} onChange={(e)=> setName(e.target.value)} type="text" id="helper-name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Full name" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-company" class="block mb-2 text-sm font-normal text-gray-600 ">Company :</label>
                        <input value={company} onChange={(e)=> setCompany(e.target.value)} type="text" id="helper-company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Company" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-country" class="block mb-2 text-sm font-normal text-gray-600 ">Country :</label>
                        <input value={country} onChange={(e)=> setCountry(e.target.value)} type="text" id="helper-country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Country" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-email" class="block mb-2 text-sm font-normal text-gray-600 ">Email :</label>
                        <input value={email} onChange={(e)=> setEmail(e.target.value)} type="email" id="helper-email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter a valid email adress" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-phone" class="block mb-2 text-sm font-normal text-gray-600 ">Phone :</label>
                        <input value={phone} onChange={(e)=> setPhone(e.target.value)} type="text" id="helper-phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="+216 71 789 710" required />
                    </div>
                    <div className=' mt-5 w-full'>
                        <label for="helper-message" class="block mb-2 text-sm font-normal text-gray-600 ">Your Message :</label>
                        <textarea value={message} onChange={(e)=> setMessage(e.target.value)} rows={8} id="helper-message" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required ></textarea>
                    </div>
                    {pending ?
                        <button disabled type="button" className=' mt-5 w-full text-white text-xl bg-blue-900 py-3 rounded-sm font-semibold'>
                            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                            </svg>
                        </button>
                    :
                        <button type='submit' className=' mt-5 w-full text-white text-xl bg-blue-900 py-3 rounded-sm font-semibold'>Send message</button>
                    }
                </form>
            </div>
            {/*MapAndContactDetails*/}
            <div className='col-span-1 bg-gray-100 rounded-sm py-4 px-6 h-fit'>
                <h2 className='mb-4 text-blue-900 font-normal text-xl'>Location & Contact details :</h2>
                <div className=' flex items-center gap-5 mb-4'>
                    <i className="fa-solid fa-phone text-white text-xl bg-blue-900 p-4 rounded-full"></i>
                    <p className='text-gray-600'>Phone : +216 71 789 710</p>
                </div>
                <div className=' flex items-center gap-5 mb-4'>
                    <i className="fa-solid fa-print text-white text-xl bg-blue-900 p-4 rounded-full"></i>
                    <p className='text-gray-600'>Fax : +216 71 789 213</p>
                </div>
                <div className=' flex items-center gap-5 mb-4'>
                    <i className="fa-solid fa-envelope text-white text-xl bg-blue-900 p-4 rounded-full"></i>
                    <p className='text-gray-600'>contact@aftechnology.com.tn</p>
                </div>
                <div className=' flex items-center gap-5 mb-4'>
                    <i className="fa-solid fa-map-location-dot text-white text-xl bg-blue-900 p-4 rounded-full"></i>
                    <p className='text-gray-600'>4-4 Rue Chaabane El Bhouri,<br />Tunis 1002, Tunisia</p>
                </div>
                <h2 className='mb-4 text-blue-900 font-normal text-xl'>Map :</h2>
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d199.63174393448926!2d10.183140696720736!3d36.81593417914116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91f28d1fe0a686b8!2sA%26F%20Technology!5e0!3m2!1sfr!2stn!4v1674912923677!5m2!1sfr!2stn" className=' w-full' height="450" style={{border: 0}} referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        {/* FOOTER */}
        <Footer/>
    </>
  )
}

export default Contact