import React from 'react'
import Footer from './Footer/Footer'
import ProductTypeCard from './Products/ProductTypeCard'

import lubrificationImage from '../assets/img/HomeCarrouselImage2.jpg'
import filtrationImage from '../assets/img/HomeCarrouselImage3.jpg'
import applianceImage from '../assets/img/HomeCarrouselImage5.jpg'
import wateringImage from '../assets/img/HomeCarrouselImage4.jpg'
import ProductTypeCardV2 from './Products/ProductTypeCardV2'
import { Helmet } from 'react-helmet'

function ProductsHome() {
  return (
    <>
        <Helmet>
            <title>A&F Technology | Products</title>
            <meta name="description" content="We are a specialized industrial engineering company known for leading advanced refrigeration and fluid handling solutions, lubrication systems, advanced filtration products, and water treatment solutions. With extensive expertise and a commitment to excellence, we offer top-quality solutions at competitive prices. Our focus on innovation, technical prowess, and customer satisfaction sets us apart as a trusted global leader." />
        </Helmet>
        {/*TopPage*/}
        <div className=' h-96 bg-gradient-to-b from-blue-900 to-blue-900 via-sky-900 text-white'>
            <div className='text-center items-center justify-center flex flex-col h-full pt-10'>
                <h1 className=' lg:text-7xl md:text-5xl font-semibold text-3xl'>Our equipments and products</h1>
                <h3 className=' md:text-2xl mt-6 font-thin ' >The perfect solutions for your future projects</h3>
            </div>
        </div>
        {/*PageContentV1*/}
        {/*
        <div className=' container max-w-screen-xl mx-auto p-4 my-4 grid lg:grid-cols-5 sm:grid-cols-2 lg:gap-6 gap-5 grid-cols-1'>
            <ProductTypeCard title='Appliance Production' href='/Appliance' img={applianceImage} big={true} />
            <ProductTypeCard title='Lubrification' href='/Lubrification' img={lubrificationImage} big={false} />
            <ProductTypeCard title='Filtration' href='/Filtration' img={filtrationImage} big={false} />
            <ProductTypeCard title='Watering System' href='/Watering' img={wateringImage} big={true} />
        </div>
        */}
        {/*PageContentV2*/}
        {/**/}
        <div className=' container max-w-screen-xl mx-auto p-4 my-4 grid md:grid-cols-4 sm:grid-cols-2 lg:gap-6 gap-5 grid-cols-1'>
            <ProductTypeCardV2 title='Appliance Production' href='/Appliance' img={applianceImage} big={true} />
            <ProductTypeCardV2 title='Lubrification' href='/Lubrification' img={lubrificationImage} big={false} />
            <ProductTypeCardV2 title='Filtration' href='/Filtration' img={filtrationImage} big={false} />
            <ProductTypeCardV2 title='Watering System' href='/Watering' img={wateringImage} big={true} />
        </div>
        
        {/* FOOTER */}
        <Footer/>
    </>
  )
}

export default ProductsHome